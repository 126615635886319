@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


/*CSS reset*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/*CSS reset end*/

/* Regular */
@font-face {
  font-family: 'Calibri';
  src: url('./fonts/calibri-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: 'Calibri';
  src: url('./fonts/calibri-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: 'Calibri';
  src: url('./fonts/calibri-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Bold Italic */
@font-face {
  font-family: 'Calibri';
  src: url('./fonts/calibri-bold-italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

div {
  scroll-margin-top: 64px;

  @media (min-width:801px) {
    scroll-margin-top: 100px;

  }

}

body {
  margin: 0 !important;
}