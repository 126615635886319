.carousel {
  padding-bottom: 12px;
  padding-top: 8px;
  overflow: hidden;
}

.dot-list-red li button {
  border-color: #63432E;
}

.dot-list-green li button {
  border-color: #3A5338;
}

.react-multi-carousel-item {
  min-width: 160px;
  margin-right: 16px;
}

@media(min-width:1250px) {
  .dot-list-green {
    display: none;
  }

  .dot-list-red {
    display: none;
  }
}